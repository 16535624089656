import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import {
  BASE,
  CompetitorSource
} from '@app/modules/shared/constants/competitor-products/competitor-products.constant';
import {
  CompetitorProductAddonType,
  ProductReference,
  ProductReferenceOverview
} from '@app/modules/shared/models/product.model';

@Component({
  selector: 'app-composite-edit-sections',
  templateUrl: './composite-edit-sections.component.html',
  styleUrls: ['./composite-edit-sections.component.sass']
})
export class CompositeEditSectionsComponent {
  isListView = true;
  referenceId: number;

  @Input() bbraunProductPageFlag = false;

  private _reference = null;
  @Input() readOnly = false;
  @Input()
  set reference(value: ProductReference | null) {
    this.referenceId = value.id;
    this._reference = value.items
      .map((item) => {
        item = { ...item, ...item.material };
        delete item.material;
        return item;
      })
      .filter((item) => item.ratioType !== BASE);
  }
  get reference(): any {
    return this._reference;
  }

  @Input()
  productId: number;

  private _referencesOverview = null;

  @Input()
  set referencesOverview(value) {
    if (value) {
      const filteredReferencedMaterials = value.referencedMaterials
        .filter((item) => item.source !== CompetitorSource.COMPETITOR)
        .map((material, index) => {
          if (material.id === this.reference[index].id) {
            return {
              ...material,
              position: this.reference[index].position,
              quantity: this.reference[index].quantity
            };
          }
        });
      this._referencesOverview = {
        ...value,
        referencedMaterials: filteredReferencedMaterials
      };
    }
  }

  get referencesOverview(): ProductReferenceOverview | null {
    return this._referencesOverview;
  }

  @Output() navigateToProductClicked = new EventEmitter<boolean>();

  displayedColumns = ['pos', 'code', 'description', 'quantity', 'actions'];

  scrollerContainer: HTMLElement;
  scroller: HTMLElement;
  itemWidth: number;
  currentItem = 1;

  readonly type = CompetitorProductAddonType.REFERENCE;

  @Output()
  onEditClick = new EventEmitter<{
    queryParams: {
      form: string;
      itemId?: number;
    };
    referencesOverview: ProductReferenceOverview;
  }>();

  @Output()
  onDeleteClick = new EventEmitter<{
    referencesOverview: ProductReferenceOverview;
  }>();

  matListReferenceStatus = [
    {
      key: 'country',
      label: 'Country Reference'
    },
    { key: 'similarity', label: 'Similarity' }
  ];

  constructor(private readonly router: Router) {}

  toggleViewClick() {
    this.isListView = !this.isListView;
    if (!this.isListView) {
      setTimeout(() => {
        this.scrollerContainer = document.querySelector('.scroller-container');
        this.scroller = this.scrollerContainer.querySelector('.scroller');
        this.itemWidth =
          this.scrollerContainer.querySelector('.item').clientWidth;
      }, 0);
    }
  }

  scrollToNextItem() {
    if (
      this.scroller.scrollLeft < this.scroller.scrollWidth - this.itemWidth &&
      this.currentItem < this.reference.length
    ) {
      this.currentItem++;
      this.scroller.scrollBy({
        left: this.itemWidth,
        top: 0,
        behavior: 'smooth'
      });
    } else {
      this.scroller.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      this.currentItem = 1;
    }
  }
  scrollToPrevItem() {
    if (this.scroller.scrollLeft !== 0) {
      this.scroller.scrollBy({
        left: -this.itemWidth,
        top: 0,
        behavior: 'smooth'
      });
      this.currentItem--;
    } else {
      this.scroller.scrollTo({
        left: this.scroller.scrollWidth,
        top: 0,
        behavior: 'smooth'
      });
      this.currentItem = this.reference.length;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index === 0 && this.scroller.scrollLeft === 0) {
      this.scroller.scrollBy({
        left: this.itemWidth * (this.currentItem - 1),
        top: 0,
        behavior: 'auto'
      });
    }
  }
}
