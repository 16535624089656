<div class="font-weight-bold font-m mb-xs">Product Details</div>
<app-details-list
  [rowsFn]="bbraunProductPageFlag ? rowsFnBbraunPage : rowsFn"
  [entity]="referencedMaterial"
>
</app-details-list>

<div class="font-m mb-xs mt-m" *ngIf="bbraunProductPageFlag">
  <span class="font-weight-bold">
    Packing information ({{ dataSource.data.length }})
  </span>
  <table mat-table [dataSource]="dataSource" class="w-100">
    <!-- gtin Column -->
    <ng-container matColumnDef="gtin">
      <th class="font-weight-normal" mat-header-cell *matHeaderCellDef>GTIN</th>
      <td mat-cell *matCellDef="let row">{{ row.globalTradeItemNumber }}</td>
    </ng-container>

    <!-- packagingUnit Column -->
    <ng-container matColumnDef="packagingUnit">
      <th class="font-weight-normal" mat-header-cell *matHeaderCellDef>
        Packaging Unit
      </th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>

    <!-- unitOfMeasure Column -->
    <ng-container matColumnDef="unitOfMeasure">
      <th class="font-weight-normal" mat-header-cell *matHeaderCellDef>
        Unit Of Measure
      </th>
      <td mat-cell *matCellDef="let row">{{ row.unitOfMeasure }}</td>
    </ng-container>

    <!-- pzn Column -->
    <ng-container matColumnDef="pzn">
      <th class="font-weight-normal" mat-header-cell *matHeaderCellDef>PZN</th>
      <td mat-cell *matCellDef="let row">{{ row.pharmaceuticalNumber }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="font-weight-bold font-m mb-xs mt-m">Images (1)</div>
<div class="d-grid cols-6 gap">
  <app-thumbnail-image
    [imgSrc]="referencedMaterial?.image"
  ></app-thumbnail-image>
</div>
