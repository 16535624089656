<div class="mb-l" *ngIf="allReferences?.length !== 0; else noResults">
  <mat-form-field>
    <mat-label>Country Reference</mat-label>
    <mat-select [value]="selectedCountry" (valueChange)="filter($event, true)">
      <mat-option
        *ngFor="let country of COUNTRY_LABELS | keyvalue: sortOrder"
        [value]="country.key"
      >
        {{ country.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="!loading">
    <div class="text-gray font-weight-bold font-s">* [C] - Composite Reference</div>
    <mat-accordion>
      <app-accordion-wrapper
        [expanded]="this.expandedId == reference.id ? true : false"
        [customDescription]="bbraunProductPageFlag"
        [hideDescription]="reference.items.length > 2"
        [readOnly]="isReadOnly"
        (isInEditModeChange)="onEditClick.emit({ referencesOverview, queryParams: null })"
        (enterDeleteMode)="onDeleteClick.emit({ referencesOverview }); reloadReferences()"
        (onPanelExpandClick)="onPanelExpandClick(reference, i)"
        (onPanelCollapseClick)="onPanelCollapseClick(reference.id)"
        #accordion
        *ngFor="let reference of references; let i = index"
        class="d-block mt-2xs small-expansion-panel-padding"
        [class.composite]="reference.items.length > 2"
      >
        <div accordion-description *ngIf="bbraunProductPageFlag">
          <div
            (click)="navigateToCompetitorPage($event, reference)"
            class="small-link mr-sm"
          >
            Go to product page
          </div>
        </div>
        <div
          class="w-100"
          accordion-title
          *ngIf="reference.items.length <= 2; else compositeReferenceHeader"
        >
          <ng-container
            *ngIf="bbraunProductPageFlag; else bbraunReferenceAccordionTitle"
          >
            <ng-container
              *ngTemplateOutlet="competitorReferenceAccordionTitle"
            ></ng-container>
          </ng-container>
        </div>

        <!-- Visualize body of accordion -->
        <ng-container *ngIf="reference.items.length <= 2; else composite">
          <ng-container
            *ngIf="referencesOverview?.id === reference?.id; else loading"
          >
            <div class="font-weight-bold font-m mb-xs mt-sm">
              Reference Status
            </div>
            <mat-list dense class="mb-m">
              <mat-list-item *ngFor="let row of matListReferenceStatus">
                <span>{{ row.label }}</span>
                <span class="d-flex align-items-center">
                  <mat-icon
                    class="mr-xs flex-shrink-0"
                    appMapIcon
                    [textToMap]="referencesOverview.similarity"
                    *ngIf="row.label === 'Similarity'"
                  ></mat-icon
                  >{{ referencesOverview?.[row.key] ?? '-' | titlecase }}</span
                >
              </mat-list-item>
              <mat-list-item *ngIf="bbraunProductPageFlag">
                <span>Reference Type</span>
                <span class="d-flex align-items-center">
                  {{ setReferenceType(referencesOverview?.ratio) }}
                </span>
              </mat-list-item>
              <mat-list-item
                *ngFor="
                  let row of [
                    { key: 'difference', label: 'Difference' },
                    { key: 'note', label: 'Comment' }
                  ]
                "
              >
                <span>{{ row.label }}</span>
                <span>{{ referencesOverview?.[row.key] ?? '-' }}</span>
              </mat-list-item>
            </mat-list>
            <app-product-referenced-material-data
              [referencedMaterial]="referencesOverview.referencedMaterials[0]"
              [bbraunProductPageFlag]="bbraunProductPageFlag"
            >
            </app-product-referenced-material-data>
          </ng-container>
        </ng-container>
        <ng-template #composite>
          <ng-container
            *ngIf="referencesOverview?.id === reference?.id; else loading"
          >
            <app-composite-edit-sections
              [reference]="reference"
              [productId]="_productId"
              [readOnly]="isReadOnly"
              [referencesOverview]="referencesOverview"
              [bbraunProductPageFlag]="bbraunProductPageFlag"
              (onEditClick)="
                onEditClick.emit({
                  referencesOverview: $event.referencesOverview,
                  queryParams: $event.queryParams
                })
              "
              (onDeleteClick)="onDeleteClick.emit({ referencesOverview }); reloadReferences()"
              (navigateToProductClicked)="navigateToCompetitorPage()"
            >
            </app-composite-edit-sections>
          </ng-container>
        </ng-template>

        <ng-template #bbraunReferenceAccordionTitle>
          <div class="row gap align-items-center black">
            <span class="col-3 d-flex align-items-center font-m">
              <mat-icon
                class="mr-xs flex-shrink-0"
                appMapIcon
                [textToMap]="reference.similarity"
              >
              </mat-icon>
              <span class="ellipsis-2">
                {{ reference.items[0]?.material?.itemCode | trimLeadingZeros }}
              </span>
            </span>
            <span class="col-7 ellipsis-2 font-m">
              {{ reference.items[0]?.material?.defaultText }}
            </span>
            <span class="item-country-wrapper font-m">
              {{
                reference.country === 'USA'
                  ? 'USA'
                  : (reference.country | titlecase)
              }}
            </span>
          </div>
        </ng-template>
        <ng-template #competitorReferenceAccordionTitle>
          <div class="row gap align-items-center justify-content-between black">
            <span
              class="col-3 d-flex align-items-center font-m item-code-wrapper"
            >
              <mat-icon
                class="mr-xs flex-shrink-0"
                appMapIcon
                [textToMap]="reference.similarity"
              >
              </mat-icon>
              <span class="ellipsis-2">
                {{ reference.items[0]?.material?.itemCode | trimLeadingZeros }}
              </span>
            </span>
            <span class="col ellipsis-2 font-m">
              {{ reference.items[0]?.material?.manufacturerName }}
            </span>
            <span class="item-country-wrapper font-m">
              {{
                reference.country === 'USA'
                  ? 'USA'
                  : (reference.country | titlecase)
              }}
            </span>
            <span class="item-link font-m text-right">
              <span class="font-m" *ngIf="reference.items.length > 2">
                '[C]'
              </span>
              <button
                type="button"
                mat-icon-button
                (click)="navigateToCompetitorPage($event, reference)"
                matTooltip="Go to product page"
              >
                <mat-icon>launch</mat-icon>
              </button>
            </span>
          </div>
        </ng-template>

        <ng-template #compositeReferenceHeader>
          <div class="w-100 font-m">
            <div
              class="row gap composite-heading align-items-center pl-s justify-content-between pr-ml"
            >
              <span
                class="col-3 d-flex align-items-center font-m item-code-wrapper"
              >
                <mat-icon
                  class="mr-xs flex-shrink-0"
                  appMapIcon
                  [textToMap]="reference.similarity"
                >
                </mat-icon>
                <span class="ellipsis-2">
                  {{ reference | getFieldsFromBase: 'itemCode' }}
                </span>
              </span>
              <span class="col font-m">
                {{ reference | getFieldsFromBase: 'manufacturerName' }}
              </span>
              <span class="item-country-wrapper font-m">
                {{
                  reference.country === 'USA'
                    ? 'USA'
                    : (reference.country | titlecase)
                }}
              </span>
              <span class="item-link font-m text-right mr-2xs">
                <span
                  class="font-m font-weight-bold"
                  *ngIf="reference.items.length > 2"
                >
                  [C]
                </span>
                <button
                  type="button"
                  mat-icon-button
                  matTooltip="Go to product page"
                  (click)="navigateToCompetitorPage($event, reference)"
                  *ngIf="bbraunProductPageFlag"
                >
                  <mat-icon>launch</mat-icon>
                </button>
              </span>
            </div>
            <mat-list *ngIf="expandedId !== reference?.id" class="d-block mb-s">
              <mat-list-item
                *ngFor="let item of extractBaseReference(reference)"
              >
                <span class="col-1 font-m">
                  <span class="item-position-background">{{
                    item.position
                  }}</span></span
                >
                <span class="col-2 font-m">{{ item.material.itemCode | trimLeadingZeros }}</span>
                <span class="col-7 font-m ellipsis-2">{{
                  item.material.defaultText
                }}</span>
                <span class="col-2 font-m text-right"
                  >{{ item.quantity }} pcs</span
                >
              </mat-list-item>
            </mat-list>
          </div>
        </ng-template>

        <ng-template #loading>
          <cx-heart-beat [loading]="true"></cx-heart-beat>
        </ng-template>
      </app-accordion-wrapper>
    </mat-accordion>
    <mat-paginator
      appBBraunPaginator
      #paginator
      [pageSize]="pageConfig.pageSize"
      [pageIndex]="pageConfig.pageIndex"
      [length]="pageConfig.pageLength"
      hidePageSize="true"
      (page)="onPage($event.pageIndex)"
      *ngIf="pageConfig.pageLength > pageConfig.pageSize"
    >
    </mat-paginator>
  </ng-container>
  <h4 *ngIf="references?.length === 0" class="text-gray">
    No references found with the current filter
  </h4>
</div>

<ng-template #noResults>
  <ng-container *ngIf="bbraunProductPageFlag; else noResultsCompetitors">
    <div class="no-results-wrapper ml-2xl">
      <mat-icon class="w-100 h-100" svgIcon="by-reference-empty"></mat-icon>
    </div>
    <h3 class="mb-l ml-xl text-gray">No referenced products yet</h3>
  </ng-container>
</ng-template>

<ng-template #noResultsCompetitors>
  <div class="black-40 mt-m">No related References</div>
</ng-template>
