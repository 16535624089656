<div accordion-description *ngIf="bbraunProductPageFlag" class="text-right">
  <div class="small-link mr-sm" (click)="navigateToProductClicked.emit(true)">
    Go to product page
  </div>
</div>
<mat-tab-group
  animationDuration="0ms"
  #matTabGroup
  (selectedTabChange)="tabChanged($event)"
>
  <mat-tab label="Products">
    <div class="row pt-s">
      <button
        type="button"
        mat-icon-button
        class="mr-m"
        [ngClass]="{ 'text-green': isListView }"
        matTooltip="List View"
        (click)="toggleViewClick()"
      >
        <mat-icon>list</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        [ngClass]="{ 'text-green': !isListView }"
        matTooltip="Carousel View"
        (click)="toggleViewClick()"
      >
        <mat-icon>view_carousel</mat-icon>
      </button>
    </div>
    <div *ngIf="isListView">
      <table mat-table [dataSource]="reference" class="w-100 compact-table">
        <ng-container matColumnDef="pos">
          <th mat-header-cell *matHeaderCellDef>Pos</th>
          <td mat-cell *matCellDef="let element">
            <span class="item-position-background">{{ element.position }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element">
            {{ element.itemCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">
            {{ element.defaultText }}
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Qty</th>
          <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-right"
            [ngStyle]="{
              display: bbraunProductPageFlag === false ? 'table-cell' : 'none'
            }"
          ></th>
          <td
            mat-cell
            *matCellDef="let element"
            class="text-right"
            [ngStyle]="{
              display: bbraunProductPageFlag === false ? 'table-cell' : 'none'
            }"
          >
            <span
              *ngIf="!bbraunProductPageFlag"
              [matTooltip]="
                'Edit Reference is not possible while the product is in read-only mode'
              "
              [matTooltipDisabled]="!readOnly"
            >
              <button
                type="button"
                matTooltip="Edit"
                mat-icon-button
                [disabled]="readOnly"
                (click)="
                  onEditClick.emit({
                    referencesOverview: referencesOverview,
                    queryParams: {
                      form: type,
                      itemId: element.id
                    }
                  })
                "
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                (click)="onDeleteClick.emit({
                    referencesOverview: referencesOverview,
                  })"
                type="button"
                matTooltip="Edit"
                mat-icon-button
                [disabled]="readOnly"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <div *ngIf="!isListView">
      <div class="row pt-s align-items-baseline">
        <button
          type="button"
          mat-icon-button
          matTooltip="Previous"
          (click)="scrollToPrevItem()"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span class="mb-m mx-xs"
          >{{ currentItem }}/{{
            referencesOverview.referencedMaterials.length
          }}</span
        >
        <button
          type="button"
          mat-icon-button
          matTooltip="Next"
          (click)="scrollToNextItem()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
        <span
          *ngIf="!bbraunProductPageFlag"
          class="d-block mt-xs"
          [matTooltip]="
            'Edit Reference is not possible while the product is in read-only mode'
          "
          [matTooltipDisabled]="!readOnly"
        >
          <button
            type="button"
            mat-button
            class="cx-button-with-icon"
            color="accent"
            [disabled]="readOnly"
            (click)="
              onEditClick.emit({
                referencesOverview: referencesOverview,
                queryParams: {
                  form: type,
                  itemId:
                    referencesOverview.referencedMaterials[currentItem - 1].id
                }
              })
            "
          >
            <mat-icon class="cx-icon-in-button">edit</mat-icon>
            <span>Edit product</span>
          </button>
          <button
            type="button"
            mat-button
            class="cx-button-with-icon"
            color="accent"
            [disabled]="readOnly"
            (click)="onDeleteClick.emit({
                      referencesOverview: referencesOverview,
                     })"
          >
            <mat-icon class="cx-icon-in-button">delete</mat-icon>
            <span>Delete product</span>
          </button>
        </span>
      </div>
      <div>
        <div class="scroller-container">
          <div class="scroller">
            <div
              class="item"
              *ngFor="let item of referencesOverview.referencedMaterials"
            >
              <div class="card">
                <div class="row gap pt-m mb-m">
                  <div class="row gap w-100">
                    <span class="col-10">
                      <span class="mr-m item-position-background">{{
                        item?.position
                      }}</span>
                      <strong>{{ item | getDefaultDescription }}</strong>
                    </span>

                    <span class="col-2">{{ item?.quantity }} pcs.</span>
                  </div>
                </div>
                <app-product-referenced-material-data
                  [referencedMaterial]="item"
                >
                </app-product-referenced-material-data>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Reference Options">
    <div class="reference-options">
      <div class="row pt-s align-items-baseline">
        <div class="font-weight-bold mr-s">Reference Options</div>
        <span
          *ngIf="!bbraunProductPageFlag"
          class="mt-xs ml-l"
          [matTooltip]="
            'Edit Reference is not possible while the product is in read-only mode'
          "
          [matTooltipDisabled]="!readOnly"
        >
          <button
            type="button"
            mat-button
            class="cx-button-with-icon"
            color="accent"
            [disabled]="readOnly"
            (click)="
              onEditClick.emit({
                referencesOverview: referencesOverview,
                queryParams: {
                  form: type
                }
              })
            "
          >
            <mat-icon class="cx-icon-in-button">edit</mat-icon>
            <span>Edit Options</span>
          </button>
        </span>
      </div>

      <mat-list dense class="mb-m">
        <mat-list-item *ngFor="let row of matListReferenceStatus">
          <span>{{ row.label }}</span>
          <span class="d-flex align-items-center">
            <mat-icon
              class="mr-xs flex-shrink-0"
              appMapIcon
              [textToMap]="referencesOverview?.similarity"
              *ngIf="row.label === 'Similarity'"
            ></mat-icon
            >{{ referencesOverview?.[row.key] ?? '-' | titlecase }}</span
          >
        </mat-list-item>

        <mat-list-item
          *ngFor="
            let row of [
              { key: 'difference', label: 'Difference' },
              { key: 'note', label: 'Comment' }
            ]
          "
        >
          <span>{{ row.label }}</span>
          <span>{{ referencesOverview?.[row.key] ?? '-' }}</span>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-tab>
</mat-tab-group>
