import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HTTP_STATUS_CODES } from '@bbraun/cortex/shared';
import { Observable, tap } from 'rxjs';

import { ROUTES } from '../constants/routes/routes.constants';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router
  ) {
  }

  private handlers = {
    [HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR]: () => {
      this.router.navigate([ROUTES.ERROR.INTERNAL_SERVER_ERROR]);
    },
    [HTTP_STATUS_CODES.FORBIDDEN]: () => {
      this.router.navigate([ROUTES.ERROR.ERROR, ROUTES.ERROR.NOT_AUTHORIZED]);
    },
    [HTTP_STATUS_CODES.UNAUTHORIZED]: () => {
      this.router.navigate([ROUTES.LOGIN]);
    }
  };

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status in this.handlers) {
              this.handlers[err.status]();
            }
          }
        }
      })
    );
  }
}
