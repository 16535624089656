import {
  AfterViewInit,
  Directive,
  DoCheck,
  Host,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Directive({
  selector: '[appBBraunPaginator]'
})
export class BbraunPaginatorDirective implements AfterViewInit, DoCheck {
  private currentPage = 1;
  private readonly showTotalPages = 2;
  private readonly deltaPages = 1;
  private readonly pageGapTxt = '...';
  private buttons = [];
  private pageRange: Array<Array<number | string>> = [];
  private directiveLoaded = false;
  private numberOfPages = 0;

  constructor(
    @Host() @Self() @Optional() private readonly paginator: MatPaginator,
    private readonly vr: ViewContainerRef,
    private readonly ren: Renderer2
  ) {
    this.paginator.page.subscribe((v) => {
      this.switchPage(v.pageIndex);
    });
  }

  private buildPageNumbers() {
    const actionContainer = this.vr.element.nativeElement.querySelector(
      'div.mat-paginator-range-actions'
    );
    const nextPageNode = this.vr.element.nativeElement.querySelector(
      'button.mat-paginator-navigation-next'
    );
    if (this.buttons.length > 0) {
      this.buttons.forEach((button) => {
        this.ren.removeChild(actionContainer, button);
      });
      this.buttons.length = 0;
    }

    if (this.pageRange.length > 1) {
      this.pageRange.forEach((page: any) => {
        if (page === this.pageGapTxt) {
          return this.ren.insertBefore(
            actionContainer,
            this.createButton(page, this.paginator.pageIndex, undefined),
            nextPageNode
          );
        }
        return this.ren.insertBefore(
          actionContainer,
          this.createButton(
            page - 1,
            this.paginator.pageIndex,
            page - 1 === this.paginator.pageIndex
              ? 'mat-paginator-selected'
              : undefined
          ),
          nextPageNode
        );
      });
    }
  }

  private createButton(
    i: any,
    pageIndex: number,
    className?: string
  ): HTMLElement {
    const linkBtn = this.ren.createElement('mat-button');
    this.ren.addClass(linkBtn, 'mat-mini-fab');
    if (className) {
      this.ren.addClass(linkBtn, className);
    }
    const pagingTxt = isNaN(i) ? this.pageGapTxt : +(i + 1);
    const text = this.ren.createText(pagingTxt + '');

    this.ren.addClass(linkBtn, 'mat-custom-page');
    switch (i) {
      case pageIndex:
        // Prevent clicking on current page
        this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        break;
      case this.pageGapTxt:
        this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        this.ren.addClass(linkBtn, 'page-gap-text');
        break;
      default:
        this.ren.listen(linkBtn, 'click', () => {
          this.switchPage(i);
          this.paginator.page.emit({
            pageIndex: i,
            pageSize: this.paginator.pageSize,
            length: this.paginator.length
          });
        });
        break;
    }

    this.ren.appendChild(linkBtn, text);
    // @ts-ignore
    this.buttons.push(linkBtn);
    return linkBtn;
  }

  private initPageRange(): void {
    this.numberOfPages = this.paginator.getNumberOfPages();
    this.pageRange = this.generatePageRange(
      this.currentPage,
      this.numberOfPages,
      this.deltaPages,
      this.showTotalPages
    );
    this.buildPageNumbers();
  }

  private switchPage(i: number): void {
    this.currentPage = i + 1;
    this.paginator.pageIndex = i;
    this.initPageRange();
  }

  private generatePageRange(
    currentPage: number,
    lastPage: number,
    delta: number,
    beginEndMax: number
  ): Array<Array<number | string>> {
    // Build Pages Array
    const range = Array(lastPage)
      .fill(null)
      .map((_, index) => index + 1);
    let startMax = [...range.slice(0, beginEndMax)];
    let endMax = [...range.slice(1).slice(-beginEndMax)];
    const startMaxWithDelta = [...range.slice(0, beginEndMax + delta)];
    const endMaxWithDelta = [...range.slice(1).slice(-(beginEndMax + delta))];

    if (startMaxWithDelta.includes(currentPage)) {
      startMax = [...range.slice(0, beginEndMax + delta * 2)];
    }
    if (endMaxWithDelta.includes(currentPage)) {
      endMax = [...range.slice(1).slice(-(beginEndMax + delta * 2))];
    }
    const beginEndMaxArr = [...startMax, ...endMax];

    // @ts-ignore
    return range.reduce((pages, page) => {
      if (beginEndMaxArr.includes(page)) {
        return [...pages, page];
      }
      if (page - delta <= currentPage && page + delta >= currentPage) {
        return [...pages, page];
      }
      if (pages[pages.length - 1] !== this.pageGapTxt) {
        return [...pages, this.pageGapTxt];
      }
      return pages;
    }, []);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.directiveLoaded = true;
      this.initPageRange();
    }, 0);
  }

  ngDoCheck() {
    if (this.directiveLoaded) {
      if (this.numberOfPages !== this.paginator.getNumberOfPages()) {
        this.initPageRange();
      }
    }
  }
}
