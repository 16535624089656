import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[fallbackImage]'
})
export class DefaultImageDirective {
  @Input() fallbackImage!: string;
  constructor(private ref: ElementRef) {}

  @HostListener('error')
  loadDefault() {
    this.ref.nativeElement.src =
      this.fallbackImage || 'assets/images/no-image-icon.png';
  }
}
