import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output
} from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  @Output() clickElsewhere = new EventEmitter<MouseEvent>();

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onDocumentClick() {
    this.clickElsewhere.emit();
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    e.stopPropagation();
  }
}
