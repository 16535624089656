/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable no-underscore-dangle */
import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  defaultErrorMessages,
  ErrorMessages
} from '@app/modules/shared/validators/default-error-messages';
import { distinctUntilChanged, filter, map, Observable, startWith } from 'rxjs';

@Component({
  selector: '[appFormFieldErrors]',
  styles: [],
  template: '{{ errorMessage$ | async }}'
})
export class FormFieldErrorsComponent implements OnInit {
  @Input() formFieldControl: AbstractControl;
  errorMessage$: Observable<string>;

  @Input()
  set errorMessages(errorMessages: ErrorMessages) {
    this._errorMessages = { ...defaultErrorMessages, ...errorMessages };
  }
  get errorMessages() {
    return this._errorMessages;
  }
  private _errorMessages = defaultErrorMessages;

  constructor(private injector: Injector) {}

  ngOnInit(): void {

    this.errorMessage$ = this.formFieldControl?.statusChanges.pipe(
      startWith(this.formFieldControl.status),
      filter((status) => status === 'INVALID'),
      map(() => this.formFieldControl.errors),
      filter((errors) => Object.keys(errors).length > 0),
      distinctUntilChanged(),
      map((errors) => {
        const errorMessageKey = Object.keys(errors)[0];
        const errorMessage =
          this.errorMessages[errorMessageKey] ??
          `Missing error message for ${errorMessageKey} validator`;

        return typeof errorMessage === 'function'
          ? errorMessage(errors)
          : errorMessage;
      })
    );
  }
}
