<mat-label *ngIf="label" class="d-block mb-s">{{ label }}</mat-label>
<cx-search-bar
  #searchBar
  [placeholder]="'Type B.Braun Code'"
  [matAutocomplete]="codeAutocomplete"
  (search)="productAutocompleteSearch($event)"
  [value]="searchTerm"
  [blurOnValueChange]="false"
>
  <mat-autocomplete
    #codeAutocomplete
    (optionSelected)="setSearchBarValue($event.option.value)"
  >
    <div class="mat-option-wrapper">
      <mat-option
        class="option-heart-beat"
        disabled
        *ngIf="isAutocompleteLoading"
      >
        <cx-heart-beat
          class="w-100"
          [loading]="isAutocompleteLoading"
        ></cx-heart-beat>
      </mat-option>
      <ng-container *ngIf="filteredProductList$ | async as filteredItems">
        <mat-option
          *ngFor="let option of filteredItems.content"
          [value]="option.itemCode"
        >
          <div class="d-flex gap align-items-center">
            <span class="option-code ellipsis">{{ option.itemCode }}</span>
            <span class="w-75 ellipsis">{{ option.description }}</span>
            <span class="option-status">{{ option.salesStatus }}</span>
            <button
              type="button"
              class="small"
              mat-icon-button
              [matTooltip]="option.itemCode + ' - ' + option.description"
            >
              <mat-icon>info</mat-icon>
            </button>
          </div>
        </mat-option>
      </ng-container>
    </div>
  </mat-autocomplete>
</cx-search-bar>
