import { PageConfig } from '../../interfaces/page/page-data-config.interface';

export const TABLE_OPTIONS = {
  PAGE: 0,
  SIZE: 20
};

export const DEFAULT_PAGE_CONFIG: PageConfig = {
  pageIndex: TABLE_OPTIONS.PAGE,
  pageSize: TABLE_OPTIONS.SIZE
};
