import { Pipe, PipeTransform } from '@angular/core';

import { BASE } from '../../constants/competitor-products/competitor-products.constant';
import { ProductReference } from '../../models/product.model';

@Pipe({
  name: 'getFieldsFromBase'
})
export class GetFieldsFromBasePipe implements PipeTransform {
  transform(reference: ProductReference, field: string): string {
    const refItem = reference.items.find((item) => item.ratioType === BASE);
    return refItem?.material?.[field];
  }
}
