import { InMemoryDbService } from 'angular-in-memory-web-api';
import {
  COUNTRY_LABELS,
  RATIO_TYPES,
  REFERENCE_TYPES,
  RESTRICTION_TYPE_LABELS,
  RestrictionTypes,
} from '@shared/constants/competitor-products/competitor-products.constant';

export class InMemDataService implements InMemoryDbService {
  createDb() {
    const productClass = [
      { id: 1, name: 'Aesculap' },
      { id: 2, name: 'Hospital Care' },
      { id: 3, name: 'Avitum' }
    ];

    const differences = [
      { value: 'AUTHOR', name: 'Author' },
      { value: 'BENDING', name: 'Bending' },
      { value: 'COLOUR', name: 'Colour' },
      { value: 'CONNECTOR', name: 'Connector' },
      { value: 'DESIGN', name: 'Design' },
      { value: 'HANDLE', name: 'Handle' },
      { value: 'MATERIAL', name: 'Material' },
      { value: 'MEASUREMENTS', name: 'Measurements' },
      { value: 'SHAPE_OF_TIP', name: 'Shape of tip' },
      { value: 'TIP', name: 'Tip' },
      { value: 'SPECIAL_MODEL', name: 'Special model' },
      { value: 'OTHERS', name: 'Others' },
      { value: 'FLOOR_GRADE', name: 'Floor Grade/Pakistan' },
      { value: 'OFFICE_GRADE', name: '2nd Tier/Office Grade' }
    ];

    const countries = [
      { value: 'GLOBAL', name: COUNTRY_LABELS.GLOBAL},
      { value: 'USA', name: COUNTRY_LABELS.USA},
    ]

    const types = [
      { value: RATIO_TYPES.ONE_TO_ONE, name: REFERENCE_TYPES.ONE_TO_ONE + ' ref.'},
      { value: RATIO_TYPES.ONE_TO_MANY, name: REFERENCE_TYPES.ONE_TO_MANY + ' ref.'},
      { value: 'Case wise', name: RESTRICTION_TYPE_LABELS[RestrictionTypes.CASE_WISE]},
      { value: 'Not possible', name: RESTRICTION_TYPE_LABELS[RestrictionTypes.NOT_POSSIBLE]},
      { value: 'NO_REFERENCE_AND_RESTRICTION', name: 'No ref./res.'},
    ]

    const similarities = [ 'Equal', 'Like', 'Maybe'];

    return {
      differences,
      productClass,
      countries,
      types,
      similarities
    };
  }
}
