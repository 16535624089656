import { AfterViewChecked, Component } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { LOCAL_STORAGE } from './../../shared/constants/local-storage/local-storage.constants';

@Component({
  selector: 'app-redirect-endpoint',
  templateUrl: './redirect-endpoint.component.html',
  styleUrls: ['./redirect-endpoint.component.scss']
})
export class RedirectEndpointComponent implements AfterViewChecked {
  wasNotCalled = true;

  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router
  ) {}

  ngAfterViewChecked() {
    if (
      this.oauthService.getIdToken() &&
      this.oauthService.getAccessToken() &&
      this.wasNotCalled
    ) {
      this.wasNotCalled = false;
      const route = localStorage.getItem(LOCAL_STORAGE.REDIRECT_URL);
      this.router.navigateByUrl(route);
    }
  }
}
