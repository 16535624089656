<cx-header>
  <cx-header-back-navigator
    *ngIf="headerConfig.showBack"
    (click)="goBack()"
  ></cx-header-back-navigator>
  <cx-header-title>{{ headerConfig.title }}</cx-header-title>
  <cx-header-quickbar>
    <span
      class="d-inline-block mr-m"
      [matTooltip]="
        'Delete is not possible while the product is in read-only mode'
      "
      [matTooltipDisabled]="!headerConfig.readOnly"
    >
      <button
        *ngIf="HEADER_MENU_ITEM.DELETE | headerOption: headerConfig"
        mat-icon-button
        matTooltip="Delete{{
          headerConfig.deleteActionTitle !== null
            ? ' ' + headerConfig.deleteActionTitle
            : ''
        }}"
        class="cx-header-white-icon"
        (click)="onDeleteButtonClick()"
        data-cy="header-delete-button"
        [disabled]="headerConfig.readOnly"
      >
        <mat-icon class="white-medium-icon">delete</mat-icon>
      </button>
    </span>

    <button
      *ngIf="HEADER_MENU_ITEM.RECYCLE_BIN | headerOption: headerConfig"
      mat-icon-button
      matTooltip="Recycle Bin"
      class="cx-header-white-icon mr-m"
      (click)="onRecycleBinButtonClick()"
      data-cy="header-bin-button"
    >
      <mat-icon class="white-medium-icon">delete</mat-icon>
    </button>
    <button
      *ngIf="HEADER_MENU_ITEM.HISTORY | headerOption: headerConfig"
      mat-icon-button
      class="cx-header-white-icon mr-m"
      matTooltip="Go to history"
      (click)="onHistoryButtonClick()"
      data-cy="header-history-button"
    >
      <mat-icon class="white-medium-icon">history</mat-icon>
    </button>
  </cx-header-quickbar>
</cx-header>
