import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { CompetitorService } from '@app/modules/competitor/providers/services/competitor/competitor.service';
import { DEBOUNCE_TIME } from '@bbraun/cortex/shared';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  tap
} from 'rxjs';

import { FilteredProduct } from '../models/product.model';

@Component({
  selector: 'app-competitor-input-with-chips',
  templateUrl: './competitor-input-with-chips.component.html',
  styleUrls: ['./competitor-input-with-chips.component.sass']
})
export class CompetitorInputWithChipsComponent implements OnInit {
  @ViewChild('inputRef') inputRef: ElementRef;

  @HostBinding() class: string;

  @Input() label = 'Competitor';

  @Input() defaultSelectedProduct: FilteredProduct;
  @Input() competitors: Map<string, number>;

  @Input() formGroupName: string;
  form: FormGroup<{ id: FormControl<number>; name: FormControl<string> }>;

  formControl = new FormControl('');
  private shouldSelectProductGroup: boolean = false;

  isProductGroupAutocompleteLoading = false;

  filteredCompetitors$ = this.formControl.valueChanges.pipe(
    distinctUntilChanged(),
    tap((value) => {
      this.formControl[value === null ? 'disable' : 'enable']();
    }),
    filter((value) => value !== null),
    tap((value) => {
      this.isProductGroupAutocompleteLoading = value.length > 2;
    }),
    debounceTime(DEBOUNCE_TIME),
    switchMap((value) =>
      value.length >= 2
        ? this.competitorService.filterCompetitorByName(value)
        : of({ content: [] })
    ),
    map((response) => {
      this.isProductGroupAutocompleteLoading = false;
      return response.content;
    })
  );

  constructor(
    private rootFormGroup: FormGroupDirective,
    private readonly competitorService: CompetitorService
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    if (this.defaultSelectedProduct) {
      this.form.patchValue(this.defaultSelectedProduct);
    }

    let competitorValue = {
      name: null,
      id: null
    };
    if (this.competitors && this.competitors.size===1 ) {
      // Assuming your form control is named `formControl`
      let [[key, value]] = this.competitors.entries();

      competitorValue = {
        name: key,
        id: value // or provide a relevant ID if available
      };
      this.shouldSelectProductGroup = true;

    }


    this.onProductGroupOptionSelect(competitorValue);

  }

  onProductGroupValueRemove() {
    this.formControl.setValue('');
    this.form.patchValue({ id: null, name: null });
  }

  onProductGroupOptionSelect(value: { name: string; id: number }) {
    this.formControl.setValue(null);
    this.form.patchValue(value);


    if (this.inputRef && this.inputRef.nativeElement) {
      this.inputRef.nativeElement.value = '';
    }
  }


}
