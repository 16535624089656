import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-accordion-wrapper',
  templateUrl: './accordion-wrapper.component.html',
  styleUrls: ['./accordion-wrapper.component.sass']
})
export class AccordionWrapperComponent {
  @Input()
  set isInEditMode(value: boolean | string) {
    this._isInEditMode = coerceBooleanProperty(value);
  }
  get isInEditMode() {
    return this._isInEditMode;
  }
  private _isInEditMode = false;

  @Input()
  set showHeader(value: boolean | string) {
    this._showHeader = coerceBooleanProperty(value);
  }
  get showHeader() {
    return this._showHeader;
  }
  private _showHeader = true;

  @Input()
  set expanded(value: boolean | string) {
    this._expanded = coerceBooleanProperty(value);
  }
  get expanded() {
    return this._expanded;
  }
  private _expanded = false;

  @Input()
  set showDelete(value: boolean | string) {
    this._showDelete = coerceBooleanProperty(value);
  }
  get showDelete() {
    return this._showDelete;
  }
  private _showDelete = true;

  @Output() isInEditModeChange = new EventEmitter<any>();
  @Output() enterDeleteMode = new EventEmitter<any>();
  @Output() onPanelExpandClick = new EventEmitter<any>();
  @Output() onPanelCollapseClick = new EventEmitter<any>();
  @Input() editButtonQueryParams: Params | null = null;
  @Input() editButtonRouterLink: string | Array<any> | null = null;
  @Input() deleteButtonRouterLink: string | Array<any> | null = null;
  @Input() customDescription: boolean;
  @Input() hideDescription: boolean;
  @Input() readOnly = false;

  onEditClick(): void {
    this.isInEditModeChange.emit(true);
  }
  onDeleteClick(): void {
    this.enterDeleteMode.emit(true);
  }
}
