<cx-footer>
  <button
    mat-button
    class="cx-button-with-icon"
    [routerLink]="'/' + ROUTES.COMPETITOR_REFERENCE_SCREEN_SEARCH"
    routerLinkActive="green"
  >
    <mat-icon class="cx-icon-in-button outlined">inventory_2</mat-icon>
    <span>Competitor Products</span>
  </button>

  <button
    mat-button
    class="cx-button-with-icon"
    [routerLink]="'/' + ROUTES.COMPETITOR_SEARCH"
    routerLinkActive="green"
  >
    <mat-icon class="cx-icon-in-button outlined">business</mat-icon>
    <span>Competitors</span>
  </button>
  <button
    mat-button
    class="cx-button-with-icon"
    [routerLink]="'/' + ROUTES.BBRAUN_PRODUCTS.BASE"
    routerLinkActive="green"
  >
    <mat-icon class="mr-xs cx-icon-in-button" svgIcon="bbraun_logo"></mat-icon>
    <span>B. Braun products</span>
  </button>

  <button
    mat-button
    class="cx-button-with-icon"
    [routerLink]="'/' + ROUTES.UPLOADS"
    routerLinkActive="green"
  >
    <mat-icon class="cx-icon-in-button outlined">upload_file</mat-icon>
    <span>Uploads</span>
  </button>

  <button
    mat-button
    class="cx-button-with-icon"
    [routerLink]="'/' + ROUTES.DOWNLOADS"
    routerLinkActive="green"
  >
    <mat-icon class="cx-icon-in-button outlined">download</mat-icon>
    <span>Downloads</span>
  </button>
</cx-footer>
