import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';

import { SimilarityTypes } from '../constants/competitor-products/competitor-products.constant';

@Directive({
  selector: '[appMapIcon]'
})
export class MapIconDirective implements OnInit {
  @Input() textToMap: string;

  @HostBinding('class')
  className;

  @HostBinding('innerHTML')
  iconName;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    switch (this.textToMap) {
      case SimilarityTypes.EQUAL:
        this.className = 'text-green';
        this.iconName = 'verified';
        break;
      case SimilarityTypes.LIKE:
        this.className = 'text-yellow';
        this.iconName = 'published_with_changes';
        break;
      case SimilarityTypes.MAYBE:
        this.className = 'text-gray';
        this.iconName = 'quiz';

        break;
      default:
    }
  }
}
