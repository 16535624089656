import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ProductService } from '@app/modules/product/providers/services/product/product.service';
import { PagingResponse } from '@app/modules/shared/interfaces/responses/paging-response.interface';
import {
  PreviewBBraunProduct,
  ReferencedMaterial
} from '@app/modules/shared/models/product.model';
import { finalize, Observable, of, shareReplay, take, tap } from 'rxjs';

@Component({
  selector: 'app-search-bar-form-handler',
  templateUrl: './search-bar-form-handler.component.html',
  styleUrls: ['./search-bar-form-handler.component.sass']
})
export class SearchBarFormHandlerComponent {
  @Input()
  label = 'B.Braun Code';
  @Output()
  sendReferencedMaterial = new EventEmitter<ReferencedMaterial>();

  isAutocompleteLoading = false;

  filteredProductList$: Observable<PagingResponse<PreviewBBraunProduct>>;

  selectedValue = '';
  referencedMaterialLoading = false;
  searchTerm: Observable<string> = of('');

  constructor(
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.code) {
        this.searchTerm = of(params.code);
        this.setSearchBarValue(params.code);
      }
    });
  }

  productAutocompleteSearch(search: string) {
    if (this.selectedValue === search) {
      this.selectedValue = '';
      return;
    }
    if (search.length >= 3) {
      this.isAutocompleteLoading = true;
      this.filteredProductList$ = this.productService
        .getBBraunProductsByItemCode(search)
        .pipe(
          finalize(() => {
            this.isAutocompleteLoading = false;
            this.cdRef.detectChanges();
          })
        );
    }
  }

  setSearchBarValue(searchTerm: string): void {
    this.selectedValue = searchTerm;
    this.filteredProductList$ = of({ content: [] });
    this.productService
      .getReferenceAcknowledgement(searchTerm)
      .pipe(
        take(1),
        tap(() => {
          this.referencedMaterialLoading = true;
        }),
        finalize(() => (this.referencedMaterialLoading = false)),
        shareReplay()
      )
      .subscribe((referencedMaterial) =>
        this.sendReferencedMaterial.emit(referencedMaterial)
      );
  }
}
