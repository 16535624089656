import { Injectable } from '@angular/core';
import {
  Competitor,
  CompetitorHistory,
  CompetitorRecycleBin
} from '@app/modules/competitor/models/competitor.model';
import { TABLE_OPTIONS } from '@app/modules/shared/constants/table/table.constants';
import {
  MaterialFilter,
  Product
} from '@app/modules/shared/models/product.model';
import { HttpParamsFactory } from '@shared/factory/http-params/http-params.factory';
import {
  PageConfig,
  PageDataConfig,
  SortConfig
} from '@shared/interfaces/page/page-data-config.interface';
import { PagingResponse } from '@shared/interfaces/responses/paging-response.interface';
import { HttpService } from '@shared/service/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompetitorService {
  constructor(private readonly httpService: HttpService) {}

  getCompetitor(id: number): Observable<Competitor> {
    return this.httpService
      .request(`manufacturers/${id}`)
      .get() as Observable<Competitor>;
  }

  deleteCompetitor(id: number): Observable<Competitor> {
    return this.httpService.request('manufacturers/' + id).delete();
  }

  updateCompetitor(body: Competitor): Observable<Competitor> {
    return this.httpService
      .request('manufacturers/' + body.id)
      .body(body)
      .put();
  }

  getCompetitors(
    pageConfig: PageConfig,
    sortConfig: SortConfig
  ): Observable<PagingResponse<Competitor>> {
    const options: PageDataConfig = {
      page: pageConfig.pageIndex,
      size: pageConfig.pageSize,
      sort: `${sortConfig.sort},${sortConfig.sortDirection}`
    };
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('manufacturers')
      .params(params)
      .get() as Observable<PagingResponse<Competitor>>;
  }

  loadCompetitorHistory(
    id: number,
    pageConfig: PageConfig
  ): Observable<PagingResponse<CompetitorHistory>> {
    const options: PageDataConfig = {
      page: pageConfig.pageIndex,
      size: TABLE_OPTIONS.SIZE,
      sort: 'eventDate,desc'
    };

    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('history/manufacturer/' + id)
      .params(params)
      .get() as Observable<PagingResponse<CompetitorHistory>>;
  }

  loadRecycleBinEntries(
    pageConfig: PageConfig
  ): Observable<PagingResponse<CompetitorRecycleBin>> {
    const options = {
      page: pageConfig.pageIndex,
      size: TABLE_OPTIONS.SIZE,
      sort: 'manufacturerName,asc'
    };

    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('recycle-bin/manufacturers')
      .params(params)
      .get();
  }

  loadRecycleBinByEntry(id: number): Observable<CompetitorRecycleBin> {
    return this.httpService
      .request('recycle-bin/manufacturers/' + id)
      .get() as Observable<CompetitorRecycleBin>;
  }

  loadRecycleBinCompetitorProducts(
    competitorId: number,
    pageConfig: PageConfig
  ): Observable<PagingResponse<Product>> {
    const options = {
      page: pageConfig.pageIndex,
      size: pageConfig.pageSize,
      sort: 'itemCode,asc'
    };
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('recycle-bin/manufacturers/' + competitorId + '/materials')
      .params(params)
      .get() as Observable<PagingResponse<Product>>;
  }

  restoreCompetitorFromRecycleBin(
    id: number
  ): Observable<CompetitorRecycleBin> {
    return this.httpService
      .request('recycle-bin/manufacturers/' + id + '/restore')
      .patch() as Observable<CompetitorRecycleBin>;
  }

  createCompetitor(body: Competitor): Observable<Competitor> {
    return this.httpService
      .request('manufacturers')
      .body(body)
      .post() as Observable<Competitor>;
  }

  filterCompetitorByName(
    value: string
  ): Observable<PagingResponse<Competitor>> {
    return this.httpService
      .request('manufacturers/filter')
      .param('name', value)
      .get() as Observable<PagingResponse<Competitor>>;
  }

  filterCompetitors(
    filter: MaterialFilter,
    pageConfig: PageConfig,
    sortConfig: SortConfig
  ): Observable<PagingResponse<Competitor>> {
    const options = {
      filters: [filter],
      page: pageConfig.pageIndex,
      size: pageConfig.pageSize,
      sort: `${sortConfig.sort},${sortConfig.sortDirection}`
    };

    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('manufacturers/filter')
      .params(params)
      .get();
  }
}
