import { ValidationErrors } from '@angular/forms';

const lengthError = (requiredLength: number) => {
  const ending = requiredLength > 1 ? 's' : '';

  return `${requiredLength} character${ending} long`;
};

export const defaultErrorMessages: {
  [key: string]: string | ((errors: ValidationErrors) => string);
} = {
  required: 'This field is required',

  minlength: (errors: ValidationErrors) =>
    'This field must be at least ' +
    lengthError(errors.minlength.requiredLength),

  maxlength: (errors: ValidationErrors) =>
    'This field must be less than ' +
    lengthError(errors.maxlength.requiredLength)
};

export type ErrorMessages = typeof defaultErrorMessages;
