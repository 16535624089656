import { HttpParams } from '@angular/common/http';

import { PageDataConfig } from '../../interfaces/page/page-data-config.interface';
import { CustomHttpParamEncoderService } from '../../service/http/custom-http-param-encoder/custom-http-param-encoder';

export class HttpParamsFactory {
  constructor(public options: PageDataConfig) {}

  returnParams(params?: HttpParams): HttpParams {
    if (!params) {
      params = new HttpParams({ encoder: new CustomHttpParamEncoderService() });
    }

    if (!this.options) {
      return params;
    }

    if (this.options.page) {
      params = params.append('page', this.options.page);
    }

    if (this.options.itemCodeTerm) {
      params = params.append('itemCodeTerm', this.options.itemCodeTerm);
    }

    if (this.options.itemCode) {
      params = params.append('itemCode', this.options.itemCode);
    }

    if (this.options.filters) {
      this.options.filters.map(
        (filter) => (params = params.append(filter.type, filter.text))
      );
    }

    if (this.options.size) {
      params = params.append('size', this.options.size);
    }

    if (this.options.sort) {
      params = params.append('sort', this.options.sort);
    }

    return params;
  }
}
