import { Action as Actions, createAction, props } from '@ngrx/store';
import { User } from '@shared/interfaces/user/user.interface';
import { ViewContainerRef } from '@angular/core';

export interface GenericErrorAction {
  message: string;
  cleanup?: Actions;
  viewContainerRef?: ViewContainerRef;
}
export const genericErrorAction = createAction(
  '[APP] GENERIC_ERROR',
  props<GenericErrorAction>()
);

export const loadUserDataAction = createAction('[APP] LOAD_USER_DATA');

export const loadUserDataSuccessAction = createAction(
  '[APP] LOAD_USER_DATA_SUCCESS',
  props<{ payload: { user: User } }>()
);
