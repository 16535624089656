import * as fromProduct from '@app/modules/product/state/reducers/product.reducer';
import {
  Manufacturer,
  MaterialFilter,
  Product
} from '@app/modules/shared/models/product.model';
import * as fromRoot from '@app/store/reducers';
import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

export const productFeatureKey = 'product';

export interface ProductState {
  [fromProduct.productFeatureKey]: fromProduct.State;
}

export interface State extends fromRoot.State {
  [productFeatureKey]: ProductState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: ProductState | undefined, action: Action) {
  return combineReducers({
    [fromProduct.productFeatureKey]: fromProduct.reducer
  })(state, action);
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectProductState =
  createFeatureSelector<ProductState>(productFeatureKey);

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them usable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 *
 */

export const selectProductEntitiesState = createSelector(
  selectProductState,
  (state) => state.product
);
export const getLoading = createSelector(
  selectProductEntitiesState,
  fromProduct.selectLoading
);
export const getSortConfig = createSelector(
  selectProductEntitiesState,
  fromProduct.selectSortConfig
);
export const getPageConfig = createSelector(
  selectProductEntitiesState,
  fromProduct.selectPageConfig
);

export const getProductFilters = createSelector(
  selectProductEntitiesState,
  fromProduct.selectProductFilters
);

/**
 * Adapters created with @ngrx/entity generate
 * commonly used selector functions including
 * getting all ids in the record set, a dictionary
 * of the records by id, an array of records and
 * the total number of records. This reduces boilerplate
 * in selecting records from the entity state.
 */
export const {
  selectIds: selectProductIds,
  selectEntities: selecProductEntities,
  selectAll: selectAllProducts,
  selectTotal: selectTotalProducts
} = fromProduct.adapter.getSelectors(selectProductEntitiesState);

export const getProductById = (id: number) =>
  createSelector(selecProductEntities, (entities) => entities[id]);

export const getProductByFilterName = (type: string) =>
  createSelector(
    selectAllProducts,
    getProductFilters,
    (products: Array<Product>, filters: Array<MaterialFilter> | null) => {
      if (products && filters) {
        const filter: MaterialFilter = filters.find(
          (item) => item.type === type
        );
        if (filter && filter.type === Manufacturer.NAME) {
          return products.find(
            (product: Product) =>
              product.manufacturerName.toLowerCase() ===
              filter.text?.toLowerCase().replace(/["']/g, '')
          );
        }
      }
      return undefined;
    }
  );
