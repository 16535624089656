import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  template: '<ng-content></ng-content>',
  styleUrls: ['./badge.component.sass']
})
export class BadgeComponent {
  @Input() background = 'blue';
  @Input() color = '';

  @HostBinding('class') get hostClass() {
    return 'bg-' + this.background + ' ' + this.color;
  }
}
