import { Component } from '@angular/core';

@Component({
  selector: 'app-table-no-results',
  templateUrl: './table-no-results.component.html',
  styleUrls: ['./table-no-results.component.sass']
})
export class TableNoResultsComponent {
  constructor() {}
}
