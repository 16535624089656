<mat-list dense [ngClass]="{ 'list-with-border': bordered }">
  <ng-content></ng-content>
  <mat-list-item *ngFor="let row of rows">
    <span>
      {{ row.label }}
    </span>
    <span *ngIf="row.label != 'B.Braun Code'; else trimLeadingZeros">
      {{ row.value ?? entity?.[row.key] ?? '-' }}
    </span>
    <ng-template #trimLeadingZeros>
      <span>
      {{ row.value ?? entity?.[row.key] ?? '-'  | trimLeadingZeros }}
    </span>
    </ng-template>
  </mat-list-item>
</mat-list>
