<div class="d-flex justify-content-center my-2xl">
  <div class="mr-l by-products-empty-icon-box">
    <mat-icon class="w-100 h-100" [svgIcon]="icon"></mat-icon>
  </div>
  <div class="text-gray">
    <h3 class="font-weight-bold mb-sm mt-0">Ready to start?</h3>
    <ul class="pl-m">
      <li *ngFor="let line of lines" class="mb-m" [innerHTML]="line"></li>
    </ul>
  </div>
</div>
