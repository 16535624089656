import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-search-empty',
  templateUrl: './product-search-empty.component.html',
  styleUrls: ['./product-search-empty.component.sass']
})
export class ProductSearchEmptyComponent {
  @Input() icon = 'by-products-empty';
  @Input() lines: Array<string>;
}
