import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinCommaAndSpace'
})
export class JoinPipe implements PipeTransform {
  transform(input: Array<any>, sep = ', '): string | null {
    return input ? input.join(sep) : null;
  }
}
