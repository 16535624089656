export const ROUTES = {
  HOME: '',
  COMPETITOR: 'competitor',
  COMPETITOR_SEARCH: 'competitor/search',
  COMPETITOR_ADD: 'competitor/add',
  COMPETITOR_PRODUCT: 'competitor/product',
  COMPETITOR_PRODUCT_SEARCH: 'competitor/product/search',
  COMPETITOR_REFERENCE_SCREEN: 'competitor/reference-screen',
  COMPETITOR_REFERENCE_SCREEN_SEARCH: 'competitor/reference-screen/search',
  UPLOADS: 'uploads',
  DOWNLOADS: 'downloads',
  CREATE: 'create',
  BBRAUN_PRODUCTS: {
    BASE: 'bbraun-products'
  },
  ERROR: {
    ERROR: 'error',
    NOT_FOUND: 'not-found',
    NOT_AUTHORIZED: 'not-authorized',
    SERVER_ERROR: 'server-error',
    SESSION_EXPIRED: 'session-expired',
    INTERNAL_SERVER_ERROR: 'internal-server-error'
  },
  REDIRECT_URI: 'oauth/callback',
  LOGIN: 'login',
  SILENT_REFRESH: 'silent-refresh'
};
