<mat-expansion-panel
  [expanded]="expanded || isInEditMode"
  [disabled]="isInEditMode"
  (opened)="onPanelExpandClick.emit()"
  (closed)="onPanelCollapseClick.emit()"
  [class.green-accordion]="isInEditMode"
>
  <mat-expansion-panel-header *ngIf="showHeader">
    <mat-panel-title>
      <ng-content select="[accordion-title]"></ng-content>
    </mat-panel-title>
    <app-badge *ngIf="isInEditMode" background="white"> Editing </app-badge>
  </mat-expansion-panel-header>
  <mat-panel-description
    class="d-flex justify-content-end align-items-center h-44px"
    *ngIf="customDescription && !hideDescription"
  >
    <ng-content select="[accordion-description]"></ng-content>
  </mat-panel-description>
  <mat-panel-description
    *ngIf="
      showHeader && !isInEditMode && !customDescription && !hideDescription
    "
    class="d-flex justify-content-end h-44px align-items-center"
  >
    <span
      [matTooltip]="
        'Edit is not possible while the product is in read-only mode'
      "
      [matTooltipDisabled]="!readOnly"
    >
      <button
        (click)="onEditClick()"
        color="accent"
        class="cx-button-with-icon"
        mat-button
        [routerLink]="editButtonRouterLink"
        [replaceUrl]="true"
        [queryParams]="editButtonQueryParams"
        [disabled]="readOnly"
      >
        <mat-icon class="cx-icon-in-button">edit</mat-icon>
        <span>Edit</span>
      </button>
      <button
         [hidden]="!showDelete"
         (click)="onDeleteClick()"
         color="accent"
         class="cx-button-with-icon"
         mat-button
         [routerLink]="deleteButtonRouterLink"
         [replaceUrl]="true"
         [disabled]="readOnly"
       >
        <mat-icon class="cx-icon-in-button">delete</mat-icon>
        <span>Delete</span>
      </button>
    </span>
  </mat-panel-description>
  <ng-template matExpansionPanelContent>
    <ng-content></ng-content>
  </ng-template>
</mat-expansion-panel>
