import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformTypeReferenceScreen'
})
export class TransformTypeReferenceScreenPipe implements PipeTransform {
  transform(input: string): string {
    let result: string;
    if (input === "1:1")
      result = "Single reference"
    else if (input === "1:N")
      result = "Composite reference"
    else if (input === "Not possible")
      result = "Not possible"
    else if (input === "Case wise")
      result = "Case wise"
    else if (input === "GLOBAL")
      result = "Global"
    else if (input === "USA")
      result = "USA"
    else if (input === "MAYBE")
      result = "Maybe"
    else if (input === "EQUAL")
      result = "Equal"
    else if (input === "LIKE")
      result = "Like"
    else if (input === "No ref./res.")
      result = "No ref./res."
    return input ? result : '-';
  }
}
