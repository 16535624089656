export enum CompetitorType {
  MANUFACTURER = 'MANUFACTURER',
  DISTRIBUTOR = 'DISTRIBUTOR'
}

export enum CompetitorSource {
  COMPETITOR = 'COMPETITOR'
}

export enum CompetitorFilters {
  ITEM_CODE = 'itemCode',
  MANUFACTURER_NAME = 'manufacturerName',
  PRODUCT_CLASS = 'productClass',
  PRODUCT_DESCRIPTION = 'description',
  NAME = 'name'
}

export enum CompetitorHistoryEventActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE'
}

export enum SimilarityTypes {
  MAYBE = 'MAYBE',
  EQUAL = 'EQUAL',
  LIKE = 'LIKE'
}

export const PRODUCT_DEFAULT_DESCRIPTION_LANGUAGE = 'EN';
export const PRODUCT_DESCRIPTION_LANGUAGES = ['EN', 'NL', 'CZ', 'PT', 'IT', 'ES', 'FR', 'DE'];

export enum RestrictionTypes {
  CASE_WISE = 'CASE_WISE',
  NOT_POSSIBLE = 'NOT_POSSIBLE'
}

export const RESTRICTION_TYPE_LABELS = {
  [RestrictionTypes.CASE_WISE]: 'Case Wise',
  [RestrictionTypes.NOT_POSSIBLE]: 'Not Possible'
};

export const COUNTRY_LABELS: { [key: string]: string } = {
  All: 'All',
  GLOBAL: 'Global',
  USA: 'USA'
};

export const REFERENCES_DEFAULT_PAGE_SIZE = 6;
export const ALL = 'All';

export const MATERIAL_NOT_REFERENCED_TYPE = {
  NO_REFERENCE_OR_RESTRICTION: 'No ref./res.'
}

export const RATIO_TYPES = {
  ONE_TO_ONE: '1:1',
  ONE_TO_MANY: '1:N'
};

export const REFERENCE_TYPES = {
  ONE_TO_ONE: 'Single',
  ONE_TO_MANY: 'Composite'
};

export const COUNTRY_TYPES = {
  GLOBAL: 'Global',
  CODE: 'Country code'
};

export const SIMILARITY_TYPES = {
  [SimilarityTypes.EQUAL]: 'Equal',
  [SimilarityTypes.LIKE]: 'Like',
  [SimilarityTypes.MAYBE]: 'Maybe'
};

export const BBRAUN_TO_COMPETITOR = 'BBRAUN_TO_COMPETITOR';
export const BASE = 'BASE';
export const PART = 'PART';

export const ACTIONTEXT = {
  CREATED: 'created',
  UPDATED: 'updated',
  DELETED: 'deleted'
};
