import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

import { AuthGuard } from './modules/auth/guard/auth.guard';
import { LoginComponent } from './modules/auth/login/login.component';
import { RedirectEndpointComponent } from './modules/auth/redirect-endpoint/redirect-endpoint.component';
import { SilentRefreshComponent } from './modules/auth/silent-refresh.component';
import { ROUTES } from './modules/shared/constants/routes/routes.constants';

const routes: Routes = [
  {
    path: ROUTES.HOME,
    redirectTo: ROUTES.COMPETITOR_REFERENCE_SCREEN_SEARCH,
    pathMatch: 'full'
  },
  {
    path: ROUTES.COMPETITOR,
    loadChildren: () =>
      import('./modules/competitor/competitor.module').then(
        (m) => m.CompetitorModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES.COMPETITOR_PRODUCT,
    loadChildren: () =>
      import('./modules/product/product.module').then((m) => m.ProductModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES.COMPETITOR_REFERENCE_SCREEN,
    loadChildren: () =>
      import('./modules/reference-screen/reference-screen.module').then(
        (m) => m.ReferenceScreenModule
      ),
  },
  {
    path: ROUTES.BBRAUN_PRODUCTS.BASE,
    loadChildren: () =>
      import('./modules/bbraun-products/bbraun-products.module').then(
        (m) => m.BbraunProductsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES.UPLOADS,
    loadChildren: () =>
      import('./modules/uploads/uploads.module').then((m) => m.UploadsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES.DOWNLOADS,
    loadChildren: () =>
      import('./modules/downloads/downloads.module').then((m) => m.DownloadsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES.ERROR.ERROR,
    loadChildren: () =>
      import('./modules/error-page/error-page.module').then(
        (m) => m.ErrorPageModule
      )
  },
  { path: ROUTES.REDIRECT_URI, component: RedirectEndpointComponent },
  { path: ROUTES.LOGIN, component: LoginComponent },
  { path: ROUTES.SILENT_REFRESH, component: SilentRefreshComponent },
  { path: '**', redirectTo: ROUTES.HOME }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'disabled',
    canceledNavigationResolution: 'computed',
    preloadingStrategy: QuicklinkStrategy
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
