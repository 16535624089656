import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HEADER_MENU_ITEM } from '@shared/constants/menu/menu-items.constants';

import { HeaderConfig } from '../../interfaces/header-config/header-config.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent {
  @Input() headerConfig: HeaderConfig;

  @Output() delete = new EventEmitter();
  @Output() history = new EventEmitter();
  @Output() bin = new EventEmitter();

  HEADER_MENU_ITEM = HEADER_MENU_ITEM;

  constructor(private readonly location: Location) {}

  goBack() {
    // special case when product details page is opened in a new tab
    if (this.headerConfig.title === 'Details'){
      window.close();
      this.location.back();
    } else {
      this.location.back();
    }
  }

  onDeleteButtonClick() {
    this.delete.emit();
  }

  onRecycleBinButtonClick() {
    this.bin.emit();
  }

  onHistoryButtonClick() {
    this.history.emit();
  }
}
