import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-list',
  templateUrl: './details-list.component.html',
  styleUrls: ['./details-list.component.sass']
})
export class DetailsListComponent {
  @Input()
  set entity(value: any) {
    this._entity = value;

    if (this.rowsFn) {
      this.rows = this.rowsFn(this.entity);
    }
  }

  get entity() {
    return this._entity;
  }

  private _entity: any = null;

  @Input() rows: Array<{
    value?: any;
    key?: string;
    label: string;
  }> = [];

  @Input() rowsFn: (entity: any) => typeof this.rows = null;

  @Input()
  set bordered(value: string | boolean) {
    this._bordered = coerceBooleanProperty(value);
  }

  get bordered(): boolean {
    return this._bordered;
  }

  private _bordered: boolean = false;
}
