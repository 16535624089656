import { Injectable } from '@angular/core';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import * as AppAction from './../actions/application.actions';

@Injectable({
  providedIn: 'root'
})
export class ApplicationEffects {
  genericError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppAction.genericErrorAction),
      map((action: AppAction.GenericErrorAction) => {
        const DEFAULT_MSG = `Oh, something went wrong! Sorry for the inconvenience. We got some issues with the server.
                           Please, try it again later.`;
        this.snackBarService.setViewContainerRef(action.viewContainerRef);
        this.snackBarService.error(
          action.message ? action.message : DEFAULT_MSG
        );
        return action.cleanup ? action.cleanup : { type: 'NO_CLEANUP_ACTION' };
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly snackBarService: CxSnackbarService
  ) {
  }
}
