import { ViewportScroller } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Scroll } from '@angular/router';
import { InMemDataService } from '@app/in-memory-data.service';
import { ROOT_REDUCERS } from '@app/store/reducers';
import { CxMaterialConfigProviders } from '@bbraun/cortex';
import { CxDevToolsModule } from '@bbraun/cortex/dev-tools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from '@shared/shared.module';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { QuicklinkModule } from 'ngx-quicklink';
import { filter } from 'rxjs';
import { SNACKBAR_CONFIG, SnackbarGlobalConfig } from '@bbraun/cortex/snackbar';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { AuthGuard } from './modules/auth/guard/auth.guard';
import { HttpService } from './modules/shared/service/http/http.service';
import { ApplicationEffects } from './store/effects/application.effects';

const customSnackbarConfig: SnackbarGlobalConfig = {
  timeout: 3000,
  closeButton: true
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    QuicklinkModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(InMemDataService, {
      passThruUnknownUrl: true,
      dataEncapsulation: false,
      delay: 1000
    }),
    MatDialogModule,
    AuthModule.forRoot(),
    StoreModule.forRoot(ROOT_REDUCERS, {
      runtimeChecks: {
        // TODO DO not remove it, otherwise the create new Product modal will not work!
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([ApplicationEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    CxDevToolsModule
  ],
  providers: [HttpService, AuthGuard, ...CxMaterialConfigProviders,
              { provide: SNACKBAR_CONFIG, useValue: customSnackbarConfig }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly router: Router,
    private readonly viewportScroller: ViewportScroller,
    private readonly registry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer
  ) {
    this.setupRouterScroller();
    this.setupCustomIcons();
  }

  private setupRouterScroller() {
    this.router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        if (
          e.routerEvent.url.includes('?form=')
        ) {
          // navigation change related to product forms
          return;
        }

        if (e.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }

  private setupCustomIcons() {
    const icons = [
      { name: 'bbraun_logo', url: 'bbraun-logo.svg' },
      { name: 'upload_min', url: 'upload_min.svg' },
      {
        name: 'by-products-empty',
        url: 'competitor-product-by-products-empty.svg'
      },
      {
        name: 'search-products',
        url: 'search-products.svg'
      },
      {
        name: 'empty_list_min',
        url: 'empty_list_min.svg'
      },
      {
        name: 'by-reference-empty',
        url: 'competitor-product-by-reference-empty.svg'
      },
      { name: 'table-no-results', url: 'no_results.svg' },
      { name: 'history', url: 'history.svg' }
    ].map((icon) => ({ name: icon.name, url: 'assets/images/' + icon.url }));

    for (const icon of icons) {
      this.registry.addSvgIcon(
        icon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    }
  }
}
