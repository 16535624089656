import { User } from '@app/modules/shared/interfaces/user/user.interface';
import { createReducer, on } from '@ngrx/store';

import * as AppAction from './../actions/application.actions';

export const ApplicationFeatureKey = 'global';

export interface AppState {
  user: User | null;
  loading: boolean;
}

export const initialState: AppState = {
  user: null,
  loading: false
};

export const reducer = createReducer<AppState>(
  initialState,
  on(AppAction.loadUserDataAction, (state: AppState) => ({
    ...state,
    loading: true
  })),
  on(
    AppAction.loadUserDataSuccessAction,
    (state: AppState, { payload }: { payload: { user: User } }) => ({
      ...state,
      loading: false,
      user: payload.user
    })
  )
);

// export function ApplicationReducer(
//   state: ApplicationState = initialAppState,
//   action: Action
// ): ApplicationState {
//   return applicationReducer(state, action);
// }

export const getUser = (state: AppState) => state.user;
export const getAppLoading = (state: AppState) => state.loading;
