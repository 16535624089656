import { TABLE_OPTIONS } from '@app/modules/shared/constants/table/table.constants';
import { SortDirection } from '@app/modules/shared/constants/utils/page.constants';
import {
  PageConfig,
  SortConfig
} from '@app/modules/shared/interfaces/page/page-data-config.interface';
import {
  MaterialFilter,
  Product
} from '@app/modules/shared/models/product.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as ProductAction from '../actions/product.actions';

export const productFeatureKey = 'product';

/**
 * @ngrx/entity provides a predefined interface for handling
 * a structured dictionary of records. This interface
 * includes an array of ids, and a dictionary of the provided
 * model type by id. This interface is extended to include
 * any additional interface properties.
 */
export interface State extends EntityState<Product> {
  sortConfig: SortConfig;
  pageConfig: PageConfig;
  loading: boolean;
  filters?: Array<MaterialFilter> | null;
}

/**
 * createEntityAdapter creates an object of many helper
 * functions for single or multiple operations
 * against the dictionary of records. The configuration
 * object takes a record id selector function and
 * a sortComparer option which is set to a compare
 * function if the records are to be sorted.
 */
export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: (product: Product) => product.id!,
  sortComparer: false
});

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: State = adapter.getInitialState({
  sortConfig: {
    sort: 'itemCode',
    sortDirection: SortDirection.ASC
  },
  pageConfig: {
    pageIndex: TABLE_OPTIONS.PAGE,
    pageSize: TABLE_OPTIONS.SIZE,
    pageLength: 0
  },
  loading: false,
  filters: null
});

// Create Reducer

export const reducer = createReducer<State>(
  initialState,
  on(ProductAction.loadProductsAction, (state) => ({
    ...state,
    loading: true
  })),

  on(ProductAction.filterProductAction, (state) => ({
    ...state,
    loading: true
  })),

  on(ProductAction.loadProductsSuccessAction, (state, { products }) =>
    adapter.setAll(products, {
      ...state,
      loading: false
    })
  ),
  on(ProductAction.filterProductSuccessAction, (state, { payload }: any) =>
    adapter.setAll(payload.products, {
      ...state,
      loading: false,
      pageConfig: {
        pageIndex: payload.pageConfig.pageIndex,
        pageSize: payload.pageConfig.pageSize,
        pageLength: payload.pageConfig.pageLength
      },
      sortConfig: payload.sortConfig,
      filters: payload.filters
    })
  ),
  on(ProductAction.loadProductByIdAction, (state) => ({
    ...state,
    loading: true
  })),
  on(ProductAction.loadProductByIdSuccessAction, (state, { product }) =>
    adapter.setOne(product, {
      ...state,
      loading: false
    })
  ),

  on(ProductAction.editProductSuccessAction, (state, { product }) =>
    adapter.updateOne(product, state)
  ),

  on(ProductAction.deleteProductAction, (state) => ({
    ...state,
    loading: true
  })),

  on(ProductAction.deleteProductSuccessAction, (state, { product }) => {
    const id = product.id!;
    return adapter.removeOne(id, {
      ...state,
      loading: false
    });
  }),

  on(ProductAction.clearAllProductsAction, (state) =>
    adapter.removeAll({
      ...state,
      pageConfig: {
        pageIndex: TABLE_OPTIONS.PAGE,
        pageSize: TABLE_OPTIONS.SIZE,
        pageLength: 0
      },
      filters: null
    })
  ),

  on(ProductAction.sortCompetitorProduct, (state, { sort }) => ({
    ...state,
    sortConfig: {
      sort: sort.sort,
      sortDirection: sort.sortDirection
    }
  }))
);

export const selectLoading = (state: State) => state.loading;
export const selectSortConfig = (state: State) => state.sortConfig;
export const selectPageConfig = (state: State) => state.pageConfig;
export const selectProductFilters = (state: State) => state.filters;
