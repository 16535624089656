import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';

import { RedirectEndpointComponent } from './redirect-endpoint/redirect-endpoint.component';
import { SilentRefreshComponent } from './silent-refresh.component';

@NgModule({
  imports: [
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true
      }
    })
  ],
  declarations: [RedirectEndpointComponent, SilentRefreshComponent],
  exports: [RedirectEndpointComponent, SilentRefreshComponent]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
