import { Environment } from '@app/modules/shared/interfaces/environment/environment.interface';

export const environment: Environment = {
  develop: true,
  environment: 'DEV',
  enableProdMode: false,
  backendUrl: 'https://xref.dev.bbraun.cloud/api',
  trackingUi: {
    enable: false,
    reportingUrl: '',
    apiKey: ''
  },
  auth: {
    clientId: '30846fbe-171f-4c54-94bc-61d3e0bad4ce',
    logoutUrl:
      'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/oauth2/v2.0/logout',
    issuer:
      'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/v2.0',
    scope:
      'openid profile email offline_access api://30846fbe-171f-4c54-94bc-61d3e0bad4ce/admin_scope'
  }
};
