<div class="position-relative thumbnail p-s">
  <div
    class="h-100"
    (mouseover)="hideElement = false"
    (mouseleave)="hideElement = true"
  >
    <cx-image
      width="50px"
      height="50px"
      class="d-block"
      [imgSrc]="imgSrc"
      [hidden]="hideElement"
    ></cx-image>
  </div>
  <div class="image-hover position-absolute bg-white">
    <cx-image
      width="auto"
      height="auto"
      [imgSrc]="imgSrc"
      [hidden]="hideElement"
    ></cx-image>
  </div>
</div>
