<div
  class="col-4 mx-auto mt-2xl d-flex flex-column justify-content-center text-gray"
>
  <h3 class="text-capitalize mt-0 mb-ml text-center">No Results Found</h3>
  <div class="h-185px mb-ml w-auto">
    <mat-icon svgIcon="table-no-results" class="w-100 h-100"></mat-icon>
  </div>
  <p class="mt-0 mb-m">No items were found matching your search request</p>
  <ul class="pl-m">
    <li class="mb-m">Check the spelling of your keyword</li>
    <li class="mb-m">Type at least 3 characters without space between them</li>
    <li class="mb-m">Try alternate word</li>
  </ul>
</div>
