import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '../../../environments/environment';
import { ROUTES } from '../shared/constants/routes/routes.constants';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.auth!.issuer,
  logoutUrl: environment.auth!.logoutUrl,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/' + ROUTES.REDIRECT_URI,

  clientId: environment.auth!.clientId,

  responseType: 'id_token token',

  disableAtHashCheck: true,

  clearHashAfterLogin: false,

  timeoutFactor: 0.9,

  silentRefreshRedirectUri:
    window.location.origin + '/' + ROUTES.SILENT_REFRESH,

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  // to delete scope: 'openid profile email offline_access api',

  scope: environment.auth.scope,

  showDebugInformation: !environment.enableProdMode,

  strictDiscoveryDocumentValidation: false
};
