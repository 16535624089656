import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-thumbnail-image',
  templateUrl: './thumbnail-image.component.html',
  styleUrls: ['./thumbnail-image.component.sass']
})
export class ThumbnailImageComponent {
  @Input()
  imgSrc: string;

  hideElement = true;
}
