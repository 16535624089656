import { KeyValue } from '@angular/common';
import { Product } from '@app/modules/shared/models/product.model';

export function compareStringOrNumber(
  entities: Array<Product>,
  sortkey: string,
  sortDirection: string
) {
  return sortByProperty([...entities], sortkey as keyof Product, sortDirection);
}

export function compareArrays(
  entities: Array<Product>,
  sortkey: string,
  sortDirection: string
) {
  return sortByArrayProperty(
    [...entities],
    sortkey as keyof Product,
    sortDirection
  );
}

export function sortByProperty<T>(
  array: Array<T>,
  propName: keyof T,
  order: string
): Array<T> {
  array.sort((a, b) => {
    if (a[propName] < b[propName]) {
      return -1;
    }
    if (a[propName] > b[propName]) {
      return 1;
    }
    return 0;
  });

  if (order === 'desc') {
    array.reverse();
  }
  return array;
}

export function sortByArrayProperty(
  array: Array<Product>,
  propName: keyof Product,
  order: string
): Array<Product> {
  array.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
    if (!a[propName]) {
      return -1;
    }
    if (!b[propName]) {
      return 1;
    }
    if (!a[propName] && !b[propName]) {
      return 0;
    }
    if (a[propName][0] < b[propName][0]) {
      return -1;
    }

    if (a[propName][0] > b[propName][0]) {
      return 1;
    }
    return 0;
  });

  if (order === 'desc') {
    array.reverse();
  }
  return array;
}

export const sortInOriginalOrder = (
  a: KeyValue<string, string>,
  b: KeyValue<string, string>
): number => 0;
