import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '@app/modules/product/providers/services/product/product.service';
import { ProductFacade } from '@app/modules/product/state/facades/product.facade';
import { AccordionWrapperComponent } from '@app/modules/shared/components/accordion-wrapper/accordion-wrapper.component';
import {
  BASE,
  COUNTRY_LABELS,
  RATIO_TYPES,
  REFERENCE_TYPES,
  REFERENCES_DEFAULT_PAGE_SIZE
} from '@app/modules/shared/constants/competitor-products/competitor-products.constant';
import { PageConfig } from '@app/modules/shared/interfaces/page/page-data-config.interface';
import {
  ProductReference,
  ProductReferenceOverview
} from '@app/modules/shared/models/product.model';
import { sortInOriginalOrder } from '@app/modules/shared/utilities/functions/sort-utils';

@Component({
  selector: 'app-product-reference-list',
  templateUrl: './product-reference-list.component.html',
  styleUrls: ['./product-reference-list.component.sass'],
  providers: [ProductFacade]
})
export class ProductReferenceListComponent implements AfterViewInit {
  @ViewChildren(AccordionWrapperComponent, { read: ElementRef })
  expansionPanels: QueryList<ElementRef<HTMLElement>>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() bbraunProductPageFlag = false;
  @Input() isReadOnly = false;

  @Input() set productId(value: number) {
    this.pageConfig.pageIndex=0;
    this._productId = value;
    this.getReferences();
  }

  @Output()
  onEditClick = new EventEmitter<{
    referencesOverview: ProductReferenceOverview;
    queryParams: {
      form: string;
      itemId?: number;
    } | null;
  }>();

  @Output()
  onDeleteClick = new EventEmitter<{
    referencesOverview: ProductReferenceOverview;
  }>();

  _productId: number;
  references: Array<ProductReference>;
  allReferences: Array<ProductReference>;
  loading = false;
  selectedCountry = COUNTRY_LABELS.All;

  referencesOverview: ProductReferenceOverview | null = null;

  expandedId: number;

  panelOpenState = false;
  hasQueryParams: boolean = false;
  referenceId: number;

  COUNTRY_LABELS = COUNTRY_LABELS;

  pageConfig: PageConfig = {
    pageIndex: 0,
    pageSize: REFERENCES_DEFAULT_PAGE_SIZE
  };

  sortOrder = sortInOriginalOrder;

  matListReferenceStatus = [
    {
      key: 'country',
      label: 'Country Reference'
    },
    { key: 'similarity', label: 'Similarity' }
  ];

  constructor(private productService: ProductService, private router: Router, private route: ActivatedRoute) {
  }

  ngAfterViewInit(): void {
    this.route.queryParams?.subscribe((params) => {
      this.hasQueryParams = Object.keys(params).length > 0;
      if (this.hasQueryParams) {
        this.referenceId = params['referenceId'];
        if (this.referenceId)
          this.onPanelExpandClickReferenceScreen(this.referenceId);
      }
    });
    this.getReferences();
  }

  onPanelExpandClick(reference: ProductReference, index: number) {
    this.expandedId = reference.id;
    this.getReferencesOverview();
  }

  onPanelExpandClickReferenceScreen(referenceId: number) {
    this.expandedId = referenceId;
    this.getReferencesOverview();
  }

  onPanelCollapseClick($event) {
    this.expandedId = null;
  }

  isInEditModeChange(
    referencesOverview: ProductReferenceOverview,
    queryParams: {
      form: string;
      itemId: number;
    } | null = null
  ) {
    this.onEditClick.emit({ referencesOverview, queryParams });
  }

  navigateToCompetitorPage($event = null, reference: ProductReference = null) {
    if ($event) {
      $event.stopPropagation();
    }
    if (reference) {
      const url = this.router.createUrlTree(
        [
          'competitor/product',
          reference.items[0].material.id
        ]
      );
      const newWindow = window.open(url.toString(), '_blank');
      if (newWindow) {
        newWindow.focus();
      }

    } else {
      const url = this.router.createUrlTree(
        [
          'competitor/product',
          this.referencesOverview.referencedMaterials[0].id
        ]
      );
      const newWindow = window.open(url.toString(), '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    }
  }

  onPage(value: number) {
    this.pageConfig.pageIndex = value;
    this.filter(this.selectedCountry);
  }

  filter(selectedCountry: string, navigateToFirstPage = false) {
    const filteredByCountry = this.allReferences.filter(
      ({ country }) =>
        country.toLocaleLowerCase() === selectedCountry.toLocaleLowerCase() ||
        selectedCountry === this.COUNTRY_LABELS.All
    );

    const pagedReferences = filteredByCountry.slice(
      this.pageConfig.pageIndex * REFERENCES_DEFAULT_PAGE_SIZE,
      this.pageConfig.pageIndex * REFERENCES_DEFAULT_PAGE_SIZE +
        REFERENCES_DEFAULT_PAGE_SIZE
    );

    // Sort Alphabetically & put Global on Top
    pagedReferences.sort((a, b) => {
      if (a.country < b.country) {
        return -1;
      }
      if (a.country > b.country || a.country === COUNTRY_LABELS.GLOBAL) {
        return 1;
      }
      return 0;
    });

    this.selectedCountry = selectedCountry;
    this.references = pagedReferences;
    this.pageConfig.pageLength = filteredByCountry.length;

    if (navigateToFirstPage) {
      this.paginator?.firstPage();
    }
  }

  extractBaseReference(reference: ProductReference) {
    return reference.items.filter((item) => item.ratioType !== BASE);
  }

  setReferenceType(ratio) {
    switch (ratio) {
      case RATIO_TYPES.ONE_TO_ONE:
        return REFERENCE_TYPES.ONE_TO_ONE;
      case RATIO_TYPES.ONE_TO_MANY:
        return REFERENCE_TYPES.ONE_TO_MANY;
      default:
        return '-';
    }
  }

  // private getReferences() {
  //   this.productService
  //     .getReferences(this._productId, this.pageConfig)
  //     .subscribe((response) => {
  //       this.allReferences = response.content;
  //       this.pageConfig = {
  //         ...this.pageConfig,
  //         pageIndex: response.number,
  //         pageLength: response.totalElements
  //       };
  //       this.filter(this.selectedCountry);
  //     });
  // }

  private getReferences() {
    if (this.bbraunProductPageFlag) {
      this.productService
        .getReferencesBbraunPage(this._productId, this.pageConfig)
        .subscribe((response) => {
          this.allReferences = response.content;
          this.pageConfig = {
            ...this.pageConfig,
            pageIndex: response.number,
            pageLength: response.totalElements
          };
          this.filter(this.selectedCountry);
        });
    } else {
      this.productService
        .getReferences(this._productId, this.pageConfig)
        .subscribe((response) => {
          this.allReferences = response.content;
          this.pageConfig = {
            ...this.pageConfig,
            pageIndex: response.number,
            pageLength: response.totalElements
          };
          this.filter(this.selectedCountry);
        });
    }
  }

  private getReferencesOverview(){
    if (this.bbraunProductPageFlag){
      this.productService
        .getReferencesOverviewBbraunPage(this._productId, this.expandedId)
        .subscribe((referencesOverview) => {
          this.referencesOverview = referencesOverview;
        });
    } else {
      this.productService
        .getReferencesOverview(this._productId, this.expandedId)
        .subscribe((referencesOverview) => {
          this.referencesOverview = referencesOverview;
        });
    }
  }

  public reloadReferences() {
    // reload references data
    setTimeout(() => this.getReferences(), 2000);
  }

  private scrollToTopOfExpansion(num: number) {
    const scrollMargin = 80;

    setTimeout(() => {
      const top =
        this.expansionPanels.get(num)?.nativeElement.getBoundingClientRect().y -
        scrollMargin;

      if (top < 0 || top >= window.innerHeight) {
        window.scrollBy({ top, behavior: 'smooth' });
      }
    }, 0);
  }
}
