import {
  MaterialFilter,
  Product
} from '@app/modules/shared/models/product.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import {
  PageConfig,
  SortConfig
} from '@shared/interfaces/page/page-data-config.interface';
import { ViewContainerRef } from '@angular/core';

export const EDIT_COMPETITOR_PRODUCT = '[COMPETITOR_PRODUCT] EDIT_PRODUCT';
export const EDIT_COMPETITOR_PRODUCT_SUCCESS =
  '[COMPETITOR_PRODUCT] EDIT_PRODUCTS_SUCCESS';

export const DELETE_COMPETITOR_PRODUCT = '[COMPETITOR_PRODUCT] DELETE_PRODUCT';
export const DELETE_COMPETITOR_PRODUCT_SUCCESS =
  '[COMPETITOR_PRODUCT] DELETE_PRODUCTS_SUCCESS';

export const LOAD_COMPETITOR_PRODUCTS = '[COMPETITOR_PRODUCT] LOAD_PRODUCTS';
export const LOAD_COMPETITOR_PRODUCTS_SUCCESS =
  '[COMPETITOR_PRODUCT] LOAD_PRODUCTS_SUCCESS';

export const LOAD_COMPETITOR_PRODUCT_BY_ID =
  '[COMPETITOR_PRODUCT] LOAD_PRODUCT_BY_ID';
export const LOAD_COMPETITOR_PRODUCT_BY_ID_SUCCESS =
  '[COMPETITOR_PRODUCT] LOAD_PRODUCTS_BY_ID_SUCCESS';

export const FILTER_COMPETITOR_PRODUCT = '[COMPETITOR_PRODUCT] FILTER_PRODUCT';
export const FILTER_COMPETITOR_PRODUCT_SUCCESS =
  '[COMPETITOR_PRODUCT] FILTER_PRODUCTS_SUCCESS';

export const SORT_COMPETITOR_PRODUCT =
  '[COMPETITOR_PRODUCT] SORT_COMPETITOR_PRODUCT';

export const CLEAR_ALL_COMPETITOR_PRODUCTS =
  '[COMPETITOR_PRODUCT] CLEAR_ALL_COMPETITOR_PRODUCTS';

export const filterProductAction = createAction(
  FILTER_COMPETITOR_PRODUCT,
  props<{
    filters: Array<MaterialFilter>;
    pageConfig: PageConfig;
    sortConfig: SortConfig;
    viewContainerRef: ViewContainerRef;
  }>()
);
export const filterProductSuccessAction = createAction(
  FILTER_COMPETITOR_PRODUCT_SUCCESS,
  props<{
    payload: {
      products: Array<Product>;
      pageConfig: PageConfig;
      sortConfig: SortConfig;
      filters?: Array<MaterialFilter>;
    };
  }>()
);

export const sortCompetitorProduct = createAction(
  SORT_COMPETITOR_PRODUCT,
  props<{ sort: SortConfig }>()
);

export const editProductAction = createAction(
  EDIT_COMPETITOR_PRODUCT,
  props<{ id: number; product: Product; viewContainerRef: ViewContainerRef }>()
);
export const editProductSuccessAction = createAction(
  EDIT_COMPETITOR_PRODUCT_SUCCESS,
  props<{ product: Update<Product>; viewContainerRef: ViewContainerRef }>()
);

export const deleteProductAction = createAction(
  DELETE_COMPETITOR_PRODUCT,
  props<{ id: number }>()
);
export const deleteProductSuccessAction = createAction(
  DELETE_COMPETITOR_PRODUCT_SUCCESS,
  props<{ product: Product }>()
);

export const loadProductsAction = createAction(LOAD_COMPETITOR_PRODUCTS);
export const loadProductsSuccessAction = createAction(
  LOAD_COMPETITOR_PRODUCTS_SUCCESS,
  props<{ products: Array<Product> }>()
);

export const loadProductByIdAction = createAction(
  LOAD_COMPETITOR_PRODUCT_BY_ID,
  props<{
    id: number;
    viewContainerRef: ViewContainerRef;
  }>()
);
export const loadProductByIdSuccessAction = createAction(
  LOAD_COMPETITOR_PRODUCT_BY_ID_SUCCESS,
  props<{ product: Product }>()
);

export const clearAllProductsAction = createAction(
  CLEAR_ALL_COMPETITOR_PRODUCTS
);
