import { Pipe, PipeTransform } from '@angular/core';

import { HEADER_MENU_ITEM } from '../../constants/menu/menu-items.constants';
import { HeaderConfig } from './../../interfaces/header-config/header-config.interface';

@Pipe({
  name: 'headerOption'
})
export class HeaderOptionPipe implements PipeTransform {
  transform(option: HEADER_MENU_ITEM, headerConfig: HeaderConfig): boolean {
    return headerConfig.menuItems.indexOf(option) > -1;
  }
}
