import { Injectable, OnDestroy, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductActions } from '@app/modules/product/state/actions';
import * as fromProduct from '@app/modules/product/state/reducers';
import {
  PageConfig,
  SortConfig
} from '@app/modules/shared/interfaces/page/page-data-config.interface';
import {
  CompetitorProductAddonType,
  CompetitorProductType,
  Manufacturer,
  MaterialFilter,
  Product
} from '@app/modules/shared/models/product.model';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Injectable()
export class ProductFacade implements OnDestroy {
  private onDestroy$ = new Subject<void>();
  productId!: number;

  product$!: Observable<Product | undefined>;
  selectedProductByFilter$!: Observable<Product | undefined>;
  productFilters$!: Observable<Array<MaterialFilter> | null>;
  loading$!: Observable<boolean | null>;
  sortConfig$!: Observable<SortConfig>;
  pageConfig$!: Observable<PageConfig>;

  $messageEditModeSubscription!: Subscription;
  editModeType!: string;

  editModeType$!: Observable<{
    type: CompetitorProductType | CompetitorProductAddonType | null;
    add: boolean;
  }>;

  constructor(
    private readonly store: Store<fromProduct.State>,
    private readonly route: ActivatedRoute
  ) {
    this.route.data.pipe(take(1)).subscribe((data) => {
      this.productId = Number(this.route.snapshot.paramMap.get('productId'));
    });
    this.product$ = this.store
      .select(fromProduct.getProductById(this.productId))
      .pipe(takeUntil(this.onDestroy$));
    this.productFilters$ = this.store
      .select(fromProduct.getProductFilters)
      .pipe(takeUntil(this.onDestroy$));
    this.loading$ = this.store
      .select(fromProduct.getLoading)
      .pipe(takeUntil(this.onDestroy$));
    this.sortConfig$ = this.store
      .select(fromProduct.getSortConfig)
      .pipe(takeUntil(this.onDestroy$));
    this.pageConfig$ = this.store
      .select(fromProduct.getPageConfig)
      .pipe(takeUntil(this.onDestroy$));
    this.selectedProductByFilter$ = this.store.select(
      fromProduct.getProductByFilterName(Manufacturer.NAME)
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  loadProducts() {
    this.store.dispatch(ProductActions.loadProductsAction());
  }

  loadProductByUrlParam(viewContainerRef: ViewContainerRef) {
    if (this.productId && this.productId > 0) {
      this.store.dispatch(
        ProductActions.loadProductByIdAction({ id: this.productId, viewContainerRef: viewContainerRef })
      );
    }
  }

  editProduct(id: number, product: Product, viewContainerRef: ViewContainerRef) {
    this.store.dispatch(ProductActions.editProductAction({ id, product, viewContainerRef }));
  }

  deleteProduct(id: number) {
    this.store.dispatch(ProductActions.deleteProductAction({ id }));
  }

  filterProduct(
    filters: Array<MaterialFilter>,
    pageConfig: PageConfig,
    sortConfig: SortConfig,
    viewContainerRef: ViewContainerRef
  ) {
    this.store.dispatch(
      ProductActions.filterProductAction({
        filters,
        pageConfig,
        sortConfig,
        viewContainerRef
      })
    );
  }

  clearProducts() {
    this.store.dispatch(ProductActions.clearAllProductsAction());
  }

  getReferences(id: string, pageConfig: PageConfig) {}
  getRestrictions(id: string, pageConfig: PageConfig) {}
}
