import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDefaultDescription'
})
export class GetDefaultDescriptionPipe implements PipeTransform {
  transform(
    input: { texts: Array<{ [key: string]: string }> },
    lang = 'EN'
  ): string {
    const result = input?.texts?.find((text) => text.language === lang);
    return input?.texts && input?.texts?.length > 0 && result
      ? result.value
      : ' - ';
  }
}
