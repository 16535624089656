import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortKeysReverse'
})
export class SortKeysReversePipe implements PipeTransform {
  transform(value: any): any[] {
    return Object.entries(value)
      .sort((a, b) => b[0].localeCompare(a[0]))
      .map(([key, value]) => ({ key, value }));
  }
}
