import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ViewContainerRef } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';

@Injectable()
export class HandleResponseService {
  constructor(
    private readonly snackBarService: CxSnackbarService,
    private readonly router: Router
  ) {
  }

  setViewContainerRef(vcr: ViewContainerRef): void {
    this.snackBarService.setViewContainerRef(vcr);
  }

  handleSuccess(
    messageSuccess,
    redirectURL?: Array<any>,
    navigationExtras: NavigationExtras = {}
  ) {
    this.snackBarService.success(messageSuccess);
    if (redirectURL) {
      this.router.navigate(redirectURL, navigationExtras);
    }
  }

  handleError(err: HttpErrorResponse) {
    if (err.error.message) {
      this.snackBarService.error(err.error.message);
    }
  }

  handleErrorWithString(err: string) {
    this.snackBarService.error(err);
  }
}
