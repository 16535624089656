import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimLeadingZeros'
})
export class TrimLeadingZerosPipe implements PipeTransform {

  transform(value: string): string {
    // Use regular expression to trim only leading zeros
    return value?.replace(/^0+(?=\d)/, '');
  }

}
