import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { environment } from '@environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

import { authCodeFlowConfig } from './modules/auth/auth-code-flow.config';

declare function ineum(command: string, ...data: Array<any>): void;
declare function instanaMonitoring(reportingUrl: string, apiKey: string): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements AfterViewInit, OnInit {
  isDevMode = this.checkForDevMode(environment.environment);

  private enableInstana = environment.trackingUi.enable;

  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.configureAuth();
  }

  ngAfterViewInit(): void {
    this.loadInstanaMonitoringScript();
    this.loadInstanaMonitoringRouterEvents();
  }

  private checkForDevMode(environment: string): boolean {
    const whiteListDevEnvironments = ['DEV', 'local'];
    return whiteListDevEnvironments.includes(environment);
  }

  private configureAuth() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => this.router.initialNavigation());
  }

  private loadInstanaMonitoringScript(): void {
    if (this.enableInstana) {
      instanaMonitoring(
        environment.trackingUi.reportingUrl,
        environment.trackingUi.apiKey
      );
    }
  }

  private loadInstanaMonitoringRouterEvents(): void {
    if (this.enableInstana) {
      this.router.events.subscribe((event) => {
        const componentName =
          this.activatedRoute.component !== null
            ? this.activatedRoute.component['name'] ||
              this.activatedRoute.component
            : 'default';

        if (event instanceof NavigationStart) {
          ineum('page', componentName);
          ineum('startSpaPageTransition');
        } else if (event instanceof NavigationEnd) {
          ineum('meta', 'component', componentName);
          ineum('endSpaPageTransition', { status: 'completed' });
        } else if (event instanceof NavigationError) {
          ineum('endSpaPageTransition', {
            status: 'error',
            url: window.location.href,
            explanation: event.error.toString()
          });
        } else if (event instanceof NavigationCancel) {
          ineum('endSpaPageTransition', {
            status: 'aborted',
            url: window.location.href,
            explanation: event.reason
          });
        }
      });
    }
  }
}
