<mat-form-field class="d-block w-100">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipList required>
    <mat-chip-row
      *ngIf="form.value.name"
      (removed)="onProductGroupValueRemove()"
    >
      {{ form.value.name }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      matInput
      type="text"
      [class.d-none]="form.value.id"
      #inputRef
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
    />
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onProductGroupOptionSelect($event.option.value)"
  >
    <mat-option *ngIf="isProductGroupAutocompleteLoading" disabled>
      <cx-heart-beat [loading]="isProductGroupAutocompleteLoading">
      </cx-heart-beat>
    </mat-option>
    <ng-container *ngIf="filteredCompetitors$ | async as filteredCompetitors">
      <ng-container *ngIf="!isProductGroupAutocompleteLoading">
        <mat-option *ngFor="let option of filteredCompetitors" [value]="option">
          {{ option.name }}
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
  <mat-error appFormFieldErrors></mat-error>
</mat-form-field>
